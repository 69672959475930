.grid-content {
  width: 18%;
  margin: 1%;
  padding: 1% 1.4%;
  box-shadow: 0px 10px 20px #ededed;
  border-radius: 4px;
  float: left;
  transition: all 0.5s;
  position: relative;
}
.grid-content:hover {
  cursor: pointer;
  /* transform属性 */
  transform: translate(0, -10px);
}
.grid-content p {
  margin-bottom: 6px;
}
.grid-content .g_1 {
  width: 100%;
  margin-bottom: 10px;
  border-radius: 4px;
  height: 8rem;
}
.grid-content .g_2 {
  font-weight: 600;
  height: 40px;
}
.grid-content .g_3 span {
  color: #f56c6c;
  font-size: 18px;
  padding: 0 4px;
}
.grid-content .g_4 {
  font-size: 13px;
}
.grid-content .g_4 span {
  color: #484848;
  font-size: 12px;
}
.grid-content .g_5 span {
  color: #409eff;
  padding: 0 4px;
  font-size: 16px;
}
.shuiyin {
  pointer-events: none;
}
.shuiyin-item {
  position: absolute;
  width: 6rem;
  height: 2.5rem;
  line-height: 2.5rem;
  text-align: center;
  left: 60%;
  top: 75%;
  font-size: 1.2rem;
  border-radius: 50% 50%;
  border: 2px solid #F0F0F0;
  transform: rotate(-30deg);
  color: #e2e2e2;
}
